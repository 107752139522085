import {
	chakra,
	useMultiStyleConfig,
	Container,
	ContainerProps,
	Select,
} from "@chakra-ui/react";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useGetAllInsuranceProductsForPremiumCalcQuery } from "src/__generated__/client.codegen";
import { BackgroundShape } from "src/components/BackgroundShape/BackgroundShape";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import { Hx } from "src/components/Heading/Heading";
import { HlsPlayer } from "src/components/HlsPlayer";
import { PremiumCalcButton } from "src/components/PremiumCalcModal/PremiumCalcButton";
import { filterInsuranceProductsForForm } from "src/components/PremiumCalcModal/utils";
import { useTranslatedString } from "src/i18n/i18n";
import { Calculator } from "src/icons";
import { usePortalType, useRole, useSiteSettings } from "src/lib/hooks";
import {
	replacePipeWithBr,
	replacePipeWithShy,
} from "src/lib/replaceStringWithReactNode";
import { useLoginInfo } from "src/queries/emil/account";

export type PremiumCalcHeroProps = {
	title: string;
	subline?: string;
	video: {
		src: string;
		poster: string;
	};
};

const useStyles = () =>
	useMultiStyleConfig("PremiumCalcHero", {
		tablet: "md",
		desktop: "xl",
	});

const PremiumCalcCta: React.FC<ContainerProps & { id: string }> = ({
	id,
	...props
}) => {
	const styles = useStyles();
	const t = useTranslatedString();
	const [insuranceProductSlug, setInsuranceProductSlug] = useState<
		string | undefined
	>();
	const {
		locale,
		brandConfig: { slug: brandSlug },
	} = useSiteSettings();
	const loginInfo = useLoginInfo();
	const rolePermission = useRole();
	const portal = usePortalType();

	const { data, isLoading } = useGetAllInsuranceProductsForPremiumCalcQuery({
		locale,
		brandSlug,
	});

	const allProducts = data?.allBrands
		.map(
			({ _allReferencingInsuranceProducts }) =>
				_allReferencingInsuranceProducts,
		)
		.flat();

	const insuranceProducts = useMemo(() => {
		const products = filterInsuranceProductsForForm(
			allProducts,
			loginInfo,
			rolePermission,
			portal,
		).filter((product) => Boolean(product.showTariffCalculator));

		return products;
	}, [allProducts, loginInfo, portal, rolePermission]);

	const sharedProps = {
		size: { base: "sm", sm: "md", md: "lg" },
	};

	const handleChange = (ev: ChangeEvent<HTMLSelectElement>) =>
		setInsuranceProductSlug(ev.target.value);

	return (
		<Container as="form" {...props}>
			<chakra.div sx={styles.ctaWrapper} opacity={isLoading ? 0 : 1}>
				<Select
					aria-label={t("premiumCalc.hero.placeholder")}
					sx={styles.select}
					name="insuranceProductSlug"
					placeholder={t("premiumCalc.hero.placeholder")}
					isInvalid={false}
					onChange={handleChange}
					{...sharedProps}
				>
					{insuranceProducts.map((product) => (
						<option key={product.slug} value={product.slug}>
							{replacePipeWithShy(product.name)}
						</option>
					))}
				</Select>

				<PremiumCalcButton
					targetGroupSlug={undefined}
					insuranceProductSlug={insuranceProductSlug}
					sx={styles.button}
					id={id}
					leftIcon={<Calculator />}
					isDisabled={!insuranceProductSlug}
					{...sharedProps}
				>
					{t("premiumCalc.hero.cta")}
				</PremiumCalcButton>
			</chakra.div>
		</Container>
	);
};

export const PremiumCalcHero: React.FC<PremiumCalcHeroProps> = ({
	title,
	subline: text,
	video,
}) => {
	const styles = useStyles();

	return (
		<chakra.div __css={styles.wrapper} data-testid="premium_calc_hero">
			<chakra.div __css={styles.main}>
				<BackgroundShape offset={65} />
				<Container maxW="container.xl" sx={styles.contentWrapper}>
					<chakra.div sx={styles.content}>
						<Hx as="h1" sx={styles.title}>
							{replacePipeWithBr(title)}
						</Hx>
						{text && (
							<DatoRichTextContent
								__css={styles.subline}
								html={text}
							/>
						)}
						<PremiumCalcCta
							id="premium_calc_hero_cta_desktop"
							sx={styles.desktopCta}
						/>
					</chakra.div>
				</Container>
			</chakra.div>

			<chakra.div __css={styles.videoWrapper}>
				<HlsPlayer
					sx={styles.video}
					src={video.src}
					poster={video.poster}
					autoPlay={true}
					loop={true}
					playsInline={true}
					muted={true}
				/>

				<PremiumCalcCta
					id="premium_calc_hero_cta_mobile"
					sx={styles.mobileCta}
				/>
			</chakra.div>
		</chakra.div>
	);
};

// 🔬 TBD: sections, hmmm … "Chromatic"?
